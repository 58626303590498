import React from "react"

export default function Home() {
  return <> <div className="main-0g" />
    <style jsx>{`
    body {
      margin: 0px;
      animation: fade-in;
      animation-duration: 4s; 
      animation-iteration-count: 1;
    }

    html {
      background: #000;
    }
    
    .main-0g {
      background: url(/desktop.png); 
      width: 100%; 
      height: 100vh; 
      background-position: center center; 
      background-size: cover; 
      animation: hue-0g; 
      animation-duration: 15s; 
      animation-iteration-count: infinite;
    }

    @keyframes hue-0g {
      from {
        filter: hue-rotate(0);
      }
      to {
        filter: hue-rotate(360deg);
      }
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    `}</style>
  </>

}
